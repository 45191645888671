// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import areObjectsUniform from '../src/lib/areObjectsUniform'
import mergeObjects from '../src/lib/mergeObjects'
import gradingScaleProps from '../proptypes/gradingScaleProps'
import updateGradingObservations from '../src/lib/updateGradingObservations'

import GradeInputWrapper from './GradeInputWrapperClassic'

const areGradingsUniform = gradings =>
  areObjectsUniform(gradings, ['grade', 'remaining'])

function createGroupGrading(gradings) {
  const groupGrading = mergeObjects(
    gradings.map(grading => {
      const newGrading = { ...grading }
      delete newGrading.observations
      return newGrading
    })
  )

  if (!groupGrading.additionalInfo) groupGrading.additionalInfo = {}

  // Use one of the gradings' OBs for the virtual group grading, it doesn't
  // matter which because the child gradings _should_ have the same OBs and
  // they're only used in the UI and not sent to the server themselves.
  groupGrading.observations = gradings[0].observations.filter(
    ({ _destroy }) => !_destroy
  )

  return groupGrading
}

function updateGradingRepeated(grading, repeated) {
  const { firstAttempt, grade } = grading

  return {
    ...grading,
    firstAttempt: repeated ? grade : null,
    grade: repeated ? null : firstAttempt,
    repeated,
  }
}

export default class GradeInputGroup extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    gradedDate: PropTypes.string.isRequired,
    gradingScale: gradingScaleProps.isRequired,
    gradings: PropTypes.arrayOf(
      PropTypes.shape({
        grading: PropTypes.shape({
          activityId: PropTypes.number,
          additionalInfo: PropTypes.object.isRequired,
          allowUploads: PropTypes.bool,
          documentId: PropTypes.string,
          fileUploadName: PropTypes.string,
          grade: PropTypes.string,
          id: PropTypes.number,
          observations: PropTypes.arrayOf(
            PropTypes.shape({
              _destroy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
              behaviourId: PropTypes.number.isRequired,
              id: PropTypes.number,
              mood: PropTypes.number.isRequired,
            })
          ).isRequired,
          qualificationId: PropTypes.number.isRequired,
          repeated: PropTypes.bool.isRequired,
          applicableUntilDate: PropTypes.string,
          applicableFromDate: PropTypes.string,
          counter: PropTypes.number,
        }).isRequired,
        gradingNames: PropTypes.shape({
          activityId: PropTypes.string.isRequired,
          additionalInfo: PropTypes.object.isRequired,
          observations: PropTypes.string.isRequired,
          documentId: PropTypes.string.isRequired,
          grade: PropTypes.string.isRequired,
          counter: PropTypes.string.isRequired,
          prefix: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          qualificationId: PropTypes.string.isRequired,
          repeated: PropTypes.string.isRequired,
          applicableFromDate: PropTypes.string.isRequired,
          applicableUntilDate: PropTypes.string.isRequired,
        }).isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    deferable: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    errorsByQualification: PropTypes.array,
  }

  state = {
    alwaysShowCurrentGradings: [],
    currentGradings: [],
    groupGrading: {},
  }

  constructor(props) {
    super(props)

    const { gradings } = props

    var currentGradings = gradings.map(g => g.grading)
    currentGradings.map(grading => {
      if (grading.id) {
        grading.server_id = grading.id
      }
    })
    const groupGrading = createGroupGrading(currentGradings)

    this.state = {
      alwaysShowCurrentGradings: !areGradingsUniform([
        groupGrading,
        ...currentGradings,
      ]),
      currentGradings,
      groupGrading,
    }
  }

  handleLastAttemptUpdate = (grade, category) => {
    const { currentGradings, groupGrading } = this.state
    const newGradings = currentGradings.map(grading => ({
      ...grading,
      category,
      grade,
    }))

    this.setState({
      currentGradings: newGradings,
      groupGrading: {
        ...groupGrading,
        category,
        grade,
      },
    })
  }

  handleObservationsChange = newObservations => {
    const { currentGradings, groupGrading } = this.state
    const newGradings = currentGradings.map(grading =>
      updateGradingObservations(grading, newObservations)
    )

    this.setState({
      currentGradings: newGradings,
      groupGrading: {
        ...groupGrading,
        observations: newObservations,
      },
    })
  }

  onUpdateGrading = ({
    grade,
    qualificationId,
    repeated,
    additionalInfo,
    applicableFromDate,
    applicableUntilDate,
    counter,
  }) => {
    const { currentGradings: oldGradings } = this.state

    const newGradings = oldGradings.map(oldGrading =>
      oldGrading.qualificationId === qualificationId
        ? {
            ...oldGrading,
            grade,
            repeated,
            additionalInfo,
            applicableFromDate,
            applicableUntilDate,
            counter,
          }
        : oldGrading
    )

    this.setState({
      currentGradings: newGradings,
      groupGrading: createGroupGrading(newGradings),
    })
  }

  onUpdateGroupAdditionalInfo = additionalInfo => {
    const { currentGradings, groupGrading } = this.state

    const newGradings = currentGradings.map(old => ({ ...old, additionalInfo }))
    this.setState({
      currentGradings: newGradings,
      groupGrading: { ...groupGrading, additionalInfo },
    })
  }

  onUpdateApplicableFromDate = applicableFromDate => {
    const { currentGradings, groupGrading } = this.state

    const newGradings = currentGradings.map(oldGrading => ({
      ...oldGrading,
      applicableFromDate,
    }))
    this.setState({
      currentGradings: newGradings,
      groupGrading: { ...groupGrading, applicableFromDate },
    })
  }

  onUpdateApplicableUntilDate = applicableUntilDate => {
    const { currentGradings, groupGrading } = this.state

    const newGradings = currentGradings.map(oldGrading => ({
      ...oldGrading,
      applicableUntilDate,
    }))
    this.setState({
      currentGradings: newGradings,
      groupGrading: { ...groupGrading, applicableUntilDate },
    })
  }

  onUpdateGroupDeferred = deferred => {
    const { currentGradings, groupGrading } = this.state

    const newGradings = currentGradings.map(oldGrading => ({
      ...oldGrading,
      deferred,
    }))
    this.setState({
      currentGradings: newGradings,
      groupGrading: { ...groupGrading, deferred },
    })
  }

  /* eslint react/no-direct-mutation-state: "off" */
  onUpdateGroupDocuments = (inputRef, documentId, fileUploadName) => {
    var findReplace = this.state.groupGrading.files.find(element => {
      return element.ref.id == inputRef.id
    })

    if (findReplace) {
      findReplace.id = documentId
      findReplace.name = fileUploadName
    } else {
      this.state.groupGrading.files = this.state.groupGrading.files.concat({
        ref: inputRef,
        id: documentId,
        name: fileUploadName,
      })
    }

    const newGradings = this.state.currentGradings.map(old => ({
      ...old,
      files: this.state.groupGrading.files,
    }))

    this.setState({
      currentGradings: newGradings,
      groupGrading: this.state.groupGrading,
    })
  }

  onUpdateGroupGrade = (grade, category) => {
    const { currentGradings, groupGrading } = this.state

    const newGradings = currentGradings.map(oldGrading => ({
      ...oldGrading,
      grade,
      category,
    }))
    this.setState({
      currentGradings: newGradings,
      groupGrading: { ...groupGrading, grade, category },
    })
  }

  onUpdateGroupRepeated = repeated => {
    const { currentGradings, groupGrading } = this.state
    const newGradings = currentGradings.map(grading =>
      updateGradingRepeated(grading, repeated)
    )
    const newGroupGrading = updateGradingRepeated(groupGrading, repeated)

    this.setState({
      currentGradings: newGradings,
      groupGrading: newGroupGrading,
    })
  }

  onToggleCurrentGradings = event => {
    event.preventDefault()

    const { currentGradings, groupGrading } = this.state

    event.currentTarget.classList.toggle('icon--angle-left')
    event.currentTarget.classList.toggle('icon--angle-down')

    if (areGradingsUniform([groupGrading, ...currentGradings])) {
      this.setState({
        alwaysShowCurrentGradings: !this.state.alwaysShowCurrentGradings,
      })
    }
  }

  onCounterUpdate = counter => {
    const { currentGradings, groupGrading } = this.state

    const newGradings = currentGradings.map(oldGrading => ({
      ...oldGrading,
      counter,
    }))

    this.setState({
      currentGradings: newGradings,
      groupGrading: { ...groupGrading, counter },
    })
  }

  showCurrentGradings() {
    const { alwaysShowCurrentGradings, currentGradings, groupGrading } =
      this.state

    return (
      alwaysShowCurrentGradings ||
      !areGradingsUniform([groupGrading, ...currentGradings])
    )
  }

  onInitFileDirectUploader = (ref, fileId) => {
    var findReplace = this.state.groupGrading.files.find(element => {
      return element.id == fileId
    })

    if (findReplace) {
      findReplace.ref = ref
    }

    this.setState({
      groupGrading: this.state.groupGrading,
    })
  }

  render() {
    const {
      directUploadsUrl,
      gradedDate,
      gradingScale,
      gradings,
      translations,
      errorsByQualification,
      deferable,
    } = this.props

    const { currentGradings, groupGrading } = this.state

    const activityId = gradings[0].grading.activityId
    const qualificationIds = gradings.map(
      ({ grading }) => grading.qualificationId
    )
    const namePrefix = `_group_gradings[activity_${activityId}][qualification_ids_${qualificationIds.join(
      '_'
    )}]`

    const additionalInfoNames = currentGradings
      .map(grading => Object.keys(grading.additionalInfo))
      .reduce((memo, additionalInfoFields) => {
        for (let field of additionalInfoFields) {
          memo[field] = `${namePrefix}[additional_info][${field}]`
        }
        return memo
      }, {})

    return (
      <div
        className={classNames({
          'grade-input-group': true,
          'grade-input-group--show-gradings': this.showCurrentGradings(),
        })}
      >
        <div className="grade-input-group__group-grading">
          <GradeInputWrapper
            directUploadsUrl={directUploadsUrl}
            gradedDate={gradedDate}
            grading={groupGrading}
            deferable={deferable}
            gradingNames={{
              additionalInfo: additionalInfoNames,
              observations: `${namePrefix}[observations_attributes]`,
              documentId: `${namePrefix}[document][]`,
              firstAttempt: `${namePrefix}[first_attempt]`,
              grade: `${namePrefix}[grade]`,
              counter: `${namePrefix}[counter]`,
              prefix: `${namePrefix}`,
              repeated: `${namePrefix}[repeated]`,
              deferred: `${namePrefix}[deferred]`,
              applicableFromDate: `${namePrefix}[applicable_from_date]`,
              applicableUntilDate: `${namePrefix}[applicable_until_date]`,
            }}
            gradingScale={gradingScale}
            onAdditionalInfoUpdate={this.onUpdateGroupAdditionalInfo}
            onDeferredUpdate={this.onUpdateGroupDeferred}
            onDocumentsUpdate={this.onUpdateGroupDocuments}
            onGradeUpdate={this.onUpdateGroupGrade}
            onLastAttemptUpdate={this.handleLastAttemptUpdate}
            onRepeatedUpdate={this.onUpdateGroupRepeated}
            onApplicableFromDateUpdate={this.onUpdateApplicableFromDate}
            onApplicableUntilDateUpdate={this.onUpdateApplicableUntilDate}
            onObservationsChange={this.handleObservationsChange}
            onToggleCurrentGradings={this.onToggleCurrentGradings}
            onCounterUpdate={this.onCounterUpdate}
            onInitFileDirectUploader={this.onInitFileDirectUploader}
            translations={translations}
            errorsByQualification={errorsByQualification}
          />
        </div>

        {UBF.gradableActivitySubqualifications ? (
          <button
            className="grade-input-group__toggle-button btn square-btn"
            onClick={this.onToggleCurrentGradings}
          />
        ) : null}

        <div className="grade-input-group__gradings">
          {currentGradings.map(currentGrading => {
            const { gradingNames, label } = gradings.find(
              ({ grading }) =>
                grading.qualificationId === currentGrading.qualificationId
            )

            return (
              <GradeInputWrapper
                directUploadsUrl={directUploadsUrl}
                key={currentGrading.qualificationId}
                gradedDate={gradedDate}
                grading={currentGrading}
                deferable={deferable}
                gradingNames={gradingNames}
                gradingScale={gradingScale}
                hidden={true}
                includeObservationInputs={true}
                label={label}
                onUpdate={this.onUpdateGrading}
                translations={translations}
                errorsByQualification={errorsByQualification}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
