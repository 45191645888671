// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import nameToId from 'src/lib/nameToId'
import Select from 'react-select'

import { selectStyles, theme, selectStylesV2 } from 'styles/ReactSelect'

export default class DynamicSelect extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(change) {
    const { name } = this.props
    const value = change
      ? this.props.multi
        ? change.map(c => c.value)
        : change.value
      : null
    const payload = { target: { name, value } }
    this.props.onSelect(payload)
  }

  render() {
    const {
      clearable,
      currentValue,
      multi,
      name,
      noResultsText,
      placeholderText,
      values,
      divClass,
      styleVersion,
      prefixClass,
      additionalClass,
      preserveSelectionOrder,
    } = this.props

    const valuesArray = multi ? currentValue : [currentValue]

    let selectedOptions = []

    if (preserveSelectionOrder)
      valuesArray.forEach(value => {
        selectedOptions.push(values.find(option => option.value == value))
      })
    else
      selectedOptions = values.filter(option =>
        valuesArray.includes(option.value)
      )

    return (
      <div
        className={divClass ? divClass : 'dynamic-select'}
        data-label={this.props.placeholderText}
        tabIndex="-1"
      >
        <Select
          className={
            prefixClass
              ? prefixClass + ' ' + additionalClass
              : 'Select ' + additionalClass
          }
          classNamePrefix={prefixClass ? prefixClass : 'Select'}
          isClearable={clearable}
          id={name ? nameToId(name) : undefined}
          isMulti={multi}
          name={name}
          noOptionsMessage={() => noResultsText}
          onChange={this.onChange}
          options={values}
          placeholder={placeholderText}
          styles={styleVersion == 2 ? selectStylesV2 : selectStyles}
          theme={theme}
          value={selectedOptions.filter(element => {
            return element !== ''
          })}
        />
      </div>
    )
  }
}

DynamicSelect.defaultProps = {
  multi: false,
}

DynamicSelect.propTypes = {
  additionalClass: PropTypes.string,
  clearable: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  multi: PropTypes.bool.isRequired,
  name: PropTypes.string,
  noResultsText: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  divClass: PropTypes.string,
  styleVersion: PropTypes.number,
  prefixClass: PropTypes.string,
  preserveSelectionOrder: PropTypes.bool,
}
