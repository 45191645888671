// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NotificationModal from '../common/NotificationModal'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { connect } from 'react-redux'
import { getBottomButtonsStore } from '../../redux/selectors'
import {
  bottomButtonClicked,
  setBottomButtons,
  setResultsErrors,
  setSessionErrors,
} from '../../redux/actions'
import {
  submitLocalSession,
  updateSessionDeviceType,
  updateSessionCustomField,
  updateSessionResultFleetIds,
  updateSessionResultPosition,
  updateSessionResultSignatureData,
} from '../../redux/app'
import { addSessionSubmitted } from '../../src/lib/idb/sessions'
import InitialsAvatar from '../common/InitialsAvatar'
import { personDisplay, personName } from '../../src/lib/peopleHelper'
import {
  checkSession,
  checkSessionResults,
} from '../../src/lib/validations/gradingValidations'
import moment from 'moment'
import { isEmpty, sortBy } from 'lodash'

import Button from '../Button'
import CandidateSignature from './CandidateSignature'
import CustomFieldInput from '../CustomFieldInput'
import DynamicSelect from '../DynamicSelect'
import FleetPicker from './FleetPicker'
import QualificationsSummary from './QualificationsSummary'
import { EntitiesSelector } from '../common/EntitiesSelector'

class Summary extends Component {
  static propTypes = {
    goToIndex: PropTypes.func.isRequired,
    goToOrca: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    setBottomButtons: PropTypes.func.isRequired,
    bottomButtonClicked: PropTypes.func.isRequired,
    resultsErrors: PropTypes.object.isRequired,
    sessionErrors: PropTypes.object,
    setResultsErrors: PropTypes.func.isRequired,
    setSessionErrors: PropTypes.func.isRequired,
    submitLocalSession: PropTypes.func.isRequired,
    updateSessionCustomField: PropTypes.func.isRequired,
    updateSessionDeviceType: PropTypes.func.isRequired,
    updateSessionResultFleetIds: PropTypes.func.isRequired,
    updateSessionResultPosition: PropTypes.func.isRequired,
    updateSessionResultSignatureData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      candidateSignatureResult: null,
      showNotification: false,
      notificationType: '',
      errors: false,
    }
  }

  componentDidMount = async () => {
    const { translations } = this.props

    var buttons = []
    if (this.gradedDateIsOnFuture()) {
      buttons = [translations.ubf.launch_orca, translations.ubf.submit]
    }
    this.props.setBottomButtons(buttons)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { bottomButtons } = newProps
    const { translations } = this.props

    if (bottomButtons && bottomButtons.clicked) {
      if (bottomButtons.clicked == translations.ubf.launch_orca) {
        this.props.bottomButtonClicked(null)
        this.props.goToOrca()
      }
      if (bottomButtons.clicked == translations.ubf.submit) {
        this.props.bottomButtonClicked(null)
        this.submitSession()
      }
    }
  }

  goBack = () => {
    this.props.goToIndex()
  }

  submitSession = async () => {
    const {
      session,
      setResultsErrors,
      setSessionErrors,
      submitLocalSession,
      translations,
    } = this.props

    const [messages, sessionErrors] = checkSession(translations, session)
    const resultsErrors = checkSessionResults(translations, session)

    const success =
      messages.length === 0 && isEmpty(sessionErrors) && isEmpty(resultsErrors)

    if (success) {
      await submitLocalSession(session.id)
    } else {
      if (!isEmpty(resultsErrors)) setResultsErrors(resultsErrors)
      if (!isEmpty(sessionErrors)) setSessionErrors(session.id, sessionErrors)
      await addSessionSubmitted(session.id)
    }

    this.setState(
      {
        errors: !success,
        notificationType: success ? 'submit_success' : 'submit_failure',
        showNotification: true,
      },
      () => {
        setTimeout(() => {
          if (success) {
            this.goBack()
          } else {
            this.handleCloseModal()
          }
        }, 2000)
      }
    )
  }

  handleCloseModal = () => {
    this.setState({ showNotification: false })
  }

  gradedDateIsOnFuture = () => {
    const { session } = this.props
    return moment().isAfter(session.gradedDate)
  }

  handleCustomFieldChange = (fieldId, { target: { value } }) => {
    const { updateSessionCustomField, session } = this.props
    updateSessionCustomField(session.id, fieldId, value)
  }

  handleDeviceTypeChange = ({ target: { value } }) => {
    this.props.updateSessionDeviceType(
      this.props.session.id,
      value ? parseInt(value) : null
    )
  }

  handleCancelSignature = () => {
    this.setState({ candidateSignatureResult: null })
  }

  handleOpenSignature = result => {
    this.setState({ candidateSignatureResult: result })
  }

  handleSaveSignature = (result, signatureData) => {
    const {
      session: { id },
      updateSessionResultSignatureData,
    } = this.props
    this.setState({ candidateSignatureResult: null })
    updateSessionResultSignatureData(id, result.id, signatureData)
  }

  handleSetResultFleetIds(resultId, fleetIds) {
    const {
      session: { id },
      updateSessionResultFleetIds,
    } = this.props

    updateSessionResultFleetIds(id, resultId, fleetIds)
  }

  updatePosition = (result, value) => {
    const { gradingSessionId, id } = result
    this.props.updateSessionResultPosition(gradingSessionId, id, value)
  }

  renderPersonRow = result => {
    const {
      resultsErrors,
      session: {
        deviceTypeId,
        gradingSessionTemplate: {
          fleets,
          overallGrading,
          signatureRequired,
          deviceTypes,
        },
        previousGradingsData,
        qualificationsData,
      },
      translations,
    } = this.props

    const overallGradingOptions = overallGrading?.overallGradingOptions

    const deviceType = deviceTypes.find(({ id }) => id === deviceTypeId)
    const entitySelectorPosition = deviceType ? (
      <EntitiesSelector
        styleVersion={1}
        prefixClass="select"
        onUpdate={event => {
          this.updatePosition(result, event.target.value)
        }}
        currentValues={result.position}
        info={{
          values: deviceType.positions.map(value => ({
            label: value,
            value,
          })),
          name: translations.activerecord.attributes.grading_session_result
            .position,
          label: '',
          noResultsText:
            translations.activerecord.errors.messages.no_records_found,
          placeholderText:
            translations.activerecord.attributes.device_type.positions,
          multi: false,
        }}
      />
    ) : null

    const hasSignature = result.signatureData || result.signatureUrl
    const resultError = resultsErrors[result.id]
    const previousGradings = previousGradingsData[result.incompleteResultId]

    const overallOptionClass = 'overall-grading-result__option'
    const overallOptionId =
      result.overall_grading_option_id || result.overallGradingOptionId
    const overallOption = overallOptionId
      ? overallGradingOptions.find(o => o.id == overallOptionId)
      : null

    return (
      <div
        className="sessions__summary--person"
        data-id={result.person.id}
        key={result.person.id}
      >
        <div className="sessions__summary--person-name">
          <div>
            <InitialsAvatar person={result.person} />
            <div>{personDisplay(result.person)}</div>
          </div>
          {fleets.length > 1 ? (
            <div>
              <FleetPicker
                fleets={fleets}
                onChange={fleetIds =>
                  this.handleSetResultFleetIds(result.id, fleetIds)
                }
                value={result.fleetIds}
              />
              {resultError?.fleetIds ? (
                <p className="sessions__summary--errorWarning">
                  <span className="icon icon--alert-error" />
                  {resultError.fleetIds}
                </p>
              ) : null}
            </div>
          ) : null}
          {entitySelectorPosition}
          <div>
            {signatureRequired && (
              <>
                <Button
                  w100={true}
                  onClick={() => this.handleOpenSignature(result)}
                >
                  {hasSignature ? (
                    <>
                      <span className="icon icon--green-check" />{' '}
                    </>
                  ) : null}
                  <span className="required-field">
                    {translations.ubf.sign}
                  </span>
                </Button>
                {resultError?.signature ? (
                  <p className="sessions__summary--errorWarning">
                    <span className="icon icon--alert-error" />
                    {resultError.signature}
                  </p>
                ) : null}
              </>
            )}
          </div>
        </div>

        <QualificationsSummary
          gradings={result.gradings}
          previousGradings={previousGradings}
          qualificationsData={qualificationsData}
        />

        {overallOption != null ? (
          <div className="sessions__summary--overall">
            <div
              className={`${overallOptionClass} ${overallOptionClass}--info`}
              style={{ color: overallOption.color }}
            >
              {overallOption.label}
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  renderCrewRow = results => {
    const { qualificationsData } = this.props.session
    const result = results[0]

    return (
      <div className="sessions__summary--person">
        <div className="sessions__summary--person-name">
          <InitialsAvatar isCrew={true} classes={'icon icon--users'} />
          <div>{this.props.translations.ubf.crew}</div>
        </div>

        <QualificationsSummary
          gradings={result.gradings}
          qualificationsData={qualificationsData}
        />
      </div>
    )
  }

  render() {
    const { session, sessionErrors, translations } = this.props

    if (!session) return null

    const {
      customFieldsData,
      gradingSessionCustomFields,
      gradingSessionTemplate,
    } = session
    const {
      candidateSignatureResult,
      errors,
      notificationType,
      showNotification,
    } = this.state

    const orderedGradingSessionResults = sortBy(
      session.gradingSessionResults,
      result => personName(result.person)
    )

    const orderedSessionCustomFields = sortBy(
      gradingSessionCustomFields,
      ({ customFieldId }) => customFieldsData[customFieldId]?.position
    )

    const deviceTypes = gradingSessionTemplate.deviceTypes

    const deviceTypesDropdown = deviceTypes.map(deviceType => ({
      value: deviceType.id.toString(),
      label: deviceType.name,
    }))

    return (
      <div className="sessions__summary">
        <NotificationModal
          visible={showNotification}
          onCloseModal={this.handleCloseModal}
          translations={translations}
          notificationType={notificationType}
          model={translations.activerecord.models.grading_session}
        />

        <div className="sessions__summary--info">
          <div className="label">
            {
              translations.activerecord.attributes.grading_session
                .grading_session_template
            }
          </div>
          <div className="value">{session.gradingSessionTemplate.name}</div>
          <div className="label">
            {translations.activerecord.attributes.grading_session.grader}
          </div>
          <div className="value">{personDisplay(session.grader)}</div>
          <div className="label">
            {translations.activerecord.attributes.grading_session.graded_date}
          </div>
          <div className="value">
            {moment(session.gradedDate).format(UBF.globalDateFormat)}
          </div>
          <div className="label">
            {translations.activerecord.attributes.grading_session.submitted_at}
          </div>
          <div className="value">
            {session.submittedAt || translations.ubf.na}
          </div>
          {deviceTypes.length > 0 && (
            <>
              <div className="label required-field">
                {
                  translations.activerecord.attributes.grading_session
                    .device_type
                }
              </div>
              <div className="input">
                <DynamicSelect
                  clearable={true}
                  name={
                    translations.activerecord.attributes.sessions_for_grader
                      .device_type
                  }
                  currentValue={session.deviceTypeId?.toString()}
                  noResultsText={
                    translations.activerecord.errors.messages.no_records_found
                  }
                  onSelect={this.handleDeviceTypeChange}
                  placeholderText={
                    translations.activerecord.attributes.grading_session
                      .device_type
                  }
                  values={deviceTypesDropdown}
                  prefixClass={'select'}
                />
                {sessionErrors?.deviceTypeId ? (
                  <p className="sessions__summary--errorWarning">
                    <span className="icon icon--alert-error" />
                    {sessionErrors.deviceTypeId}
                  </p>
                ) : null}
              </div>
            </>
          )}
          {orderedSessionCustomFields.map(sessionCustomField => {
            const customField =
              customFieldsData[sessionCustomField.customFieldId]

            if (!customField) return null

            const { fieldTypeValue } = sessionCustomField
            const { fieldType, id, listValues, name } = customField

            return (
              <React.Fragment key={id}>
                <div className={'label required-field'} key={id}>
                  {name}
                </div>
                <div className="input">
                  <CustomFieldInput
                    name={name}
                    type={fieldType}
                    value={listValues.map(value => ({
                      label: value,
                      value,
                    }))}
                    currentValue={fieldTypeValue}
                    onChange={value => this.handleCustomFieldChange(id, value)}
                    noResultsText={
                      translations.activerecord.errors.messages.no_records_found
                    }
                  />
                  {sessionErrors && sessionErrors[`customFieldId-${id}`] ? (
                    <p className="sessions__summary--errorWarning">
                      <span className="icon icon--alert-error" />
                      {translations.activerecord.errors.models.grading_session.mandatory.custom_field.replace(
                        '%{custom_field_name}',
                        name
                      )}
                    </p>
                  ) : null}
                </div>
              </React.Fragment>
            )
          })}
        </div>

        {candidateSignatureResult && (
          <CandidateSignature
            onCancel={this.handleCancelSignature}
            onSave={this.handleSaveSignature}
            result={candidateSignatureResult}
            signatureDeclaration={gradingSessionTemplate.signatureDeclaration}
          />
        )}

        {errors ? (
          <div className="sessions__summary--warning">
            <ReportProblemIcon className="quantum-layout__notification--small quantum-layout__notification--icon-yellow" />
            <div className="sessions__summary--warning-message">
              {translations.messages.actions.submission_failure}
            </div>
          </div>
        ) : null}

        {this.gradedDateIsOnFuture() ? (
          <div className="sessions__summary--people">
            {session.gradingSessionTemplate.gradeAsCrew
              ? this.renderCrewRow(orderedGradingSessionResults)
              : orderedGradingSessionResults.map(result => {
                  return this.renderPersonRow(result)
                })}
          </div>
        ) : (
          <div className="sessions__summary--warning">
            <ReportProblemIcon className="quantum-layout__notification--small quantum-layout__notification--icon-yellow" />
            <div className="sessions__summary--warning-message">
              {
                translations.activerecord.errors.models.grading.attributes.grade
                  .future_graded_date
              }
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const {
    session: { id },
  } = props
  const bottomButtons = getBottomButtonsStore(state)
  const {
    resultsErrors,
    sessions: { errors },
  } = state
  return { bottomButtons, resultsErrors, sessionErrors: errors[id] }
}

export default connect(mapStateToProps, {
  bottomButtonClicked,
  setBottomButtons,
  setResultsErrors,
  setSessionErrors,
  submitLocalSession,
  updateSessionResultFleetIds,
  updateSessionResultPosition,
  updateSessionResultSignatureData,
  updateSessionCustomField,
  updateSessionDeviceType,
})(Summary)
