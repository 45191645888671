// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { partition } from 'lodash'

import partitionActivityAndSessionGradings from '../../src/lib/partitionActivityAndSessionGradings'
import translate from '../../src/lib/translate'

import IconAction from '../IconAction'
import StandaloneGradeInput from './StandaloneGradeInput'
import OverallGrading from '../sessions_for_grader/OverallGrading'
import GradeInputGroup from './GradeInputGroup'
import AutoExpandingTextarea from '../AutoExpandingTextarea'

class GradingForm extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    incompleteMode: PropTypes.bool.isRequired,
    onGradings: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    result: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    onUpdateAbstract: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      abstract: false,
      currentAbstract: props.result.abstract || '',
    }
  }

  handleGradings = gradings => {
    const { onGradings } = this.props
    onGradings(gradings)
  }

  showAbstract = () => {
    this.setState({ abstract: !this.state.abstract })
  }

  updateCurrentAbstract = evt => {
    this.setState({ currentAbstract: evt.currentTarget.value })
  }

  updateAbstract = async () => {
    const { currentAbstract } = this.state
    const { result, session, onUpdateAbstract } = this.props

    if (onUpdateAbstract)
      onUpdateAbstract(session.id, result.id, currentAbstract)
  }

  render() {
    const {
      directUploadsUrl,
      incompleteMode,
      online,
      result,
      session,
      translations,
    } = this.props
    const {
      activitiesData,
      gradingScalesData,
      gradingSessionTemplate: { overallGrading },
      previousGradingsData,
      qualificationsData,
    } = session

    const previousGradings = previousGradingsData[result.incompleteResultId]
    const allGradings = previousGradings
      ? previousGradings.concat(result.gradings)
      : result.gradings
    const [gradeByActivityGradings, gradeBySessionGradings] =
      partitionActivityAndSessionGradings(allGradings, session)

    const session_gradings = gradeBySessionGradings.map(grading => {
      const { qualificationId, incoming } = grading
      const qualification = qualificationsData[qualificationId]
      const gradingScale = gradingScalesData[qualification.gradingScaleId]
      const deferable = gradingScale.deferable && !result.lastInCourse
      const incomingTag = incoming
        ? ` (${translate(
            'messages.gradingSessions.incomingFromPrevious',
            translations
          )})`
        : ''
      const fromPreviousSession = !!grading.incompleteResultId

      return (
        <StandaloneGradeInput
          directUploadsUrl={directUploadsUrl}
          fromPreviousSession={fromPreviousSession}
          grading={grading}
          gradingScale={gradingScale}
          incompleteMode={incompleteMode}
          key={`q${qualificationId}`}
          label={qualification.name + incomingTag}
          onGrading={grading => this.handleGradings([grading])}
          online={online}
          translations={translations}
          gradingSession={session}
          deferable={deferable}
        />
      )
    })

    const activity_gradings = gradeByActivityGradings.map(gradings => {
      const { activityId, qualificationId } = gradings[0]
      const activity = activitiesData[activityId]
      const qualification = qualificationsData[qualificationId]
      const gradingScale = gradingScalesData[qualification.gradingScaleId]
      const deferable = gradingScale.deferable && !result.lastInCourse
      const fromPreviousSession = gradings.some(
        ({ incompleteResultId }) => incompleteResultId
      )

      const [incomingGradings, sessionGradings] = partition(
        gradings,
        ({ incoming }) => incoming
      )
      let label = activity.name

      if (incomingGradings.length > 0) {
        label += ' ('

        if (sessionGradings.length > 0) {
          // The sub-gradings contain both gradings from the session and
          // incoming gradings from previously-submitted session in a course.
          label += translations.messages.gradingSessions.extraTrainingRequired
          if (incomingGradings.length > 1) {
            label += ` × ${incomingGradings.length}`
          }
        } else {
          // The sub-gradings are all incoming from a previously-submitted
          // session in a course.
          label += translations.messages.gradingSessions.incomingFromPrevious
        }

        label += ')'
      }

      const labelClassName = classnames({
        'label-tag--orca': true,
        'label-tag--orca-faded': fromPreviousSession,
      })

      return (
        <div key={`a${activityId}`}>
          <label className={labelClassName}>{label}</label>

          <GradeInputGroup
            directUploadsUrl={directUploadsUrl}
            fromPreviousSession={fromPreviousSession}
            gradings={gradings}
            gradingScale={gradingScale}
            incompleteMode={incompleteMode}
            onGradings={this.handleGradings}
            online={online}
            translations={translations}
            gradingSession={session}
            deferable={deferable}
          />
        </div>
      )
    })

    return (
      <section className="gradings-form">
        <div className="orca-abstract">
          <div className="orca-abstract__header">
            <label className="label-tag--orca" htmlFor="abstract">
              {translate(
                'activerecord.attributes.gradingSessionResult.abstract',
                translations
              )}
            </label>
            <IconAction
              color={this.state.currentAbstract !== '' ? 'green' : null}
              icon="plus-circle"
              inline={true}
              onClick={this.showAbstract}
            >
              {translations.forms.inputValues.gradingForm.buttons.comment}
            </IconAction>
          </div>
          {this.state.abstract ? (
            <AutoExpandingTextarea
              onChange={this.updateCurrentAbstract}
              onBlur={this.updateAbstract}
              value={this.state.currentAbstract}
              id="abstract"
            />
          ) : null}
        </div>
        {activity_gradings}
        {session_gradings}
        {overallGrading ? (
          <OverallGrading
            gradingSessionResult={result}
            options={overallGrading.overallGradingOptions}
            translations={translations}
          />
        ) : null}
      </section>
    )
  }
}

export default GradingForm
