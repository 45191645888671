// Copyright Northcote Technology Ltd
import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { sortBy } from 'lodash'

import DynamicSelect from './DynamicSelect'
import MoodOption from './MoodOption'
import MoodSelector from './MoodSelector'
import { NEUTRAL, behavioursByMood } from '../src/lib/behaviourUtils'

function moodsFromBehaviours(behaviours) {
  const moods = new Set()

  behaviours.forEach(behaviour => {
    behaviour.moods.forEach(mood => moods.add(mood))
  })

  return Array.from(moods)
}

function ObservationItem({
  behavioursData,
  disabled,
  faded,
  observation,
  onChangeMood,
  onRemove,
}) {
  const [expandedMoodSelector, setExpandedMoodSelector] = useState(false)
  const behaviour = behavioursData[observation.behaviourId]

  const handleChangeMood = mood => {
    if (mood !== null) {
      onChangeMood(observation, mood)
    }
    setExpandedMoodSelector(false)
  }
  const handleRemove = () => onRemove(observation)

  const className = classnames({
    'observation-item': true,
    'observation-item__faded': faded,
  })

  return (
    <div className={className}>
      <div className="observation-item--mood">
        <MoodOption
          checked={true}
          editable={!disabled}
          onChange={() => setExpandedMoodSelector(!expandedMoodSelector)}
          value={observation.mood}
        />
        {expandedMoodSelector && (
          <div className="observation-item--selector-wrapper">
            <MoodSelector
              availableValues={behaviour?.moods || []}
              editable={true}
              updateCurrentMoodType={handleChangeMood}
              value={observation.mood}
            />
          </div>
        )}
      </div>
      <span>{behaviour?.name}</span>
      {disabled ? null : (
        <div className="icon icon--small icon--cross" onClick={handleRemove} />
      )}
    </div>
  )
}

export default class ObservationSelector extends Component {
  static propTypes = {
    availableBehaviourIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    behavioursData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    faded: PropTypes.bool,
    label: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedMood: NEUTRAL,
    }
  }

  getRemainingBehavioursForSelectedMood() {
    const { availableBehaviourIds, behavioursData, value } = this.props
    const { selectedMood } = this.state

    const behaviours = availableBehaviourIds.map(id => behavioursData[id])
    const behavioursForSelectedMood =
      behavioursByMood(behaviours)[selectedMood] || []

    if (behavioursForSelectedMood.length === 0) {
      return []
    }

    const alreadyAssignedBehaviourIds = new Set(
      value.map(({ behaviourId }) => behaviourId)
    )

    return sortBy(
      behavioursForSelectedMood.filter(
        ({ id }) => !alreadyAssignedBehaviourIds.has(id)
      ),
      'name'
    )
  }

  handleChangeObservationMood = (effectedObservation, mood) => {
    this.props.onChange(
      this.props.value.map(observation =>
        observation === effectedObservation
          ? { ...observation, mood }
          : observation
      )
    )
  }

  handleRemoveObservation = observationToRemove => {
    this.props.onChange(
      this.props.value.filter(
        observation => observation !== observationToRemove
      )
    )
  }

  handleSelectBehaviour = event => {
    const { behavioursData, onChange, value } = this.props
    const behaviour = behavioursData[event.target.value]

    if (!behaviour) return

    const newObservation = {
      behaviourId: behaviour.id,
      mood: this.state.selectedMood,
    }

    onChange([...value, newObservation])
  }

  handleSelectMood = selectedMood => {
    this.setState({ selectedMood })
  }

  render() {
    const {
      availableBehaviourIds,
      behavioursData,
      disabled,
      faded,
      label,
      noResultsText,
      placeholder,
      value,
    } = this.props

    if (disabled && value.length === 0) return null

    const { selectedMood } = this.state
    const behaviours = availableBehaviourIds.map(id => behavioursData[id])
    const availableMoods = moodsFromBehaviours(behaviours)

    const className = classnames({
      'observation-selector': true,
      'observation-selector__faded': faded,
    })

    return (
      <div className={className}>
        <div className="observation-selector__header">
          <span>{label}</span>

          {disabled ? null : (
            <MoodSelector
              availableValues={availableMoods}
              editable={true}
              updateCurrentMoodType={this.handleSelectMood}
              value={selectedMood}
            />
          )}
        </div>

        {disabled ? null : (
          <DynamicSelect
            clearable={true}
            currentValue={[]}
            multi={false}
            name=""
            noResultsText={noResultsText}
            onSelect={this.handleSelectBehaviour}
            placeholderText={placeholder}
            values={this.getRemainingBehavioursForSelectedMood().map(
              ({ id, name }) => ({
                value: id.toString(),
                label: name,
              })
            )}
          />
        )}

        {value.length === 0 ? null : (
          <div className="observation-selector__items">
            {value.map(observation => (
              <ObservationItem
                behavioursData={behavioursData}
                disabled={disabled}
                faded={faded}
                key={`${observation.behaviourId}-${observation.mood}`}
                observation={observation}
                onChangeMood={this.handleChangeObservationMood}
                onRemove={this.handleRemoveObservation}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}
