// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import nameToId from 'src/lib/nameToId'
import DynamicSelect from './DynamicSelect'

const itemToOption = ({ id, name }) => ({
  label: name,
  value: id.toString(),
})

function Error({ message }) {
  return message ? <div className="error">{message}</div> : null
}

export default class OverallGradeOptionSelector extends Component {
  static propTypes = {
    currentOptionIds: PropTypes.arrayOf(PropTypes.number),
    currentOverallGradingId: PropTypes.number,
    currentTemplateIds: PropTypes.arrayOf(PropTypes.number),
    errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    overallGradingLabel: PropTypes.string.isRequired,
    overallGradingName: PropTypes.string.isRequired,
    overallGradingNoResultsText: PropTypes.string.isRequired,
    overallGradingPlaceholderText: PropTypes.string.isRequired,
    overallGradings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    templatesName: PropTypes.string.isRequired,
    templatesNoResultsText: PropTypes.string.isRequired,
    templatesPlaceholderText: PropTypes.string.isRequired,
    templatesLabel: PropTypes.string.isRequired,
    optionsName: PropTypes.string.isRequired,
    optionsNoResultsText: PropTypes.string.isRequired,
    optionsPlaceholderText: PropTypes.string.isRequired,
    optionsLabel: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      overallGradingId: props.currentOverallGradingId?.toString(),
      templateIds: props.currentTemplateIds.map(id => id.toString()),
      selectedOptionIds: props.currentOptionIds.map(id => id.toString()),
    }
  }

  handleSelectOverallGrading = event => {
    if (this.state.overallGradingId == event.target.value) return

    this.setState({
      overallGradingId: event.target.value,
      selectedOptionIds: [],
      templateIds: [],
    })
  }

  handleSelectTemplate = event => {
    this.setState({
      templateIds: event.target.value,
    })
  }

  handleSelectOptions = event => {
    this.setState({
      selectedOptionIds: event.target.value,
    })
  }

  render() {
    const {
      errors,
      optionsLabel,
      optionsName,
      optionsPlaceholderText,
      optionsNoResultsText,
      overallGradingLabel,
      overallGradingName,
      overallGradingNoResultsText,
      overallGradingPlaceholderText,
      overallGradings,
      templates,
      templatesLabel,
      templatesName,
      templatesNoResultsText,
      templatesPlaceholderText,
    } = this.props

    const { overallGradingId, selectedOptionIds, templateIds } = this.state

    const overallGrading = overallGradingId
      ? overallGradings.find(({ id }) => id == overallGradingId)
      : null
    const templatesOptions = overallGradingId
      ? templates.filter(
          template => template.overallGradingId == overallGradingId
        )
      : []
    const overallGradingOptions = overallGrading?.options || []

    return (
      <>
        <label htmlFor={nameToId(overallGradingName)}>
          {overallGradingLabel}
        </label>
        <DynamicSelect
          clearable={true}
          currentValue={overallGradingId}
          name={overallGradingName}
          noResultsText={overallGradingNoResultsText}
          onSelect={this.handleSelectOverallGrading}
          placeholderText={overallGradingPlaceholderText}
          values={overallGradings.map(itemToOption)}
        />
        <Error message={errors.metadataOverallGradingId} />

        {overallGrading ? (
          <>
            <label htmlFor={nameToId(templatesName)}>{templatesLabel}</label>
            <DynamicSelect
              clearable={true}
              currentValue={templateIds}
              multi={true}
              name={templatesName}
              noResultsText={templatesNoResultsText}
              onSelect={this.handleSelectTemplate}
              placeholderText={templatesPlaceholderText}
              values={templatesOptions.map(itemToOption)}
            />
            <Error message={errors.metadataSessionTemplateIds} />

            <label htmlFor={nameToId(optionsName)}>{optionsLabel}</label>
            <DynamicSelect
              clearable={true}
              multi={true}
              name={optionsName}
              currentValue={selectedOptionIds}
              noResultsText={optionsNoResultsText}
              placeholderText={optionsPlaceholderText}
              values={overallGradingOptions.map(itemToOption)}
              onSelect={this.handleSelectOptions}
            />
            <Error message={errors.metadataOverallGradingOptionIds} />
          </>
        ) : null}
      </>
    )
  }
}
