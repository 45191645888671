// Copyright Northcote Technology Ltd
import PropTypes from 'prop-types'

export default PropTypes.shape({
  additionalInformationFields: PropTypes.arrayOf(PropTypes.string),
  allowRepeats: PropTypes.bool.isRequired,
  hasApplicableFromDate: PropTypes.bool,
  hasApplicableUntilDate: PropTypes.bool,
  otherGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
  disqualifyingGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
  failingGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
  minimumAcceptableGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
  acceptableGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
  exemplaryGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
  retiredGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
})
