// Copyright Northcote Technology Ltd
import React from 'react'
import { groupBy, sortBy, uniq } from 'lodash'

import { GRADE_CATEGORIES } from '../../src/lib/gradingScaleUtils'

function getLowestCategory(gradings) {
  if (gradings.some(({ incomplete }) => incomplete)) {
    return 'incomplete'
  } else {
    const gradingsByCategory = groupBy(gradings, 'category')
    return (
      GRADE_CATEGORIES.find(category => gradingsByCategory[category]) || 'other'
    )
  }
}

export default function QualificationsSummary(props) {
  const { gradings, previousGradings, qualificationsData } = props
  const allGradings = previousGradings
    ? previousGradings.concat(gradings)
    : gradings
  const gradableQualifications = sortBy(
    uniq(allGradings.map(({ qualificationId }) => qualificationId)).map(
      id => qualificationsData[id]
    ),
    'identifier'
  )
  const gradingsByQualification = groupBy(allGradings, 'qualificationId')

  return (
    <ul>
      {gradableQualifications.map(({ id, identifier }) => {
        const category = getLowestCategory(gradingsByQualification[id] || [])
        const className = `qual-label label-tag label-tag--large label-tag--${category}`

        return (
          <li className={className} key={id}>
            {identifier}
          </li>
        )
      })}
    </ul>
  )
}
