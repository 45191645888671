// Copyright Northcote Technology Ltd
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DynamicSelect from './DynamicSelect'

const toOption = ([value, label]) => ({
  value,
  label,
})

const RadioOption = ({ checked, label, onChange, onClick, value }) => (
  <label>
    <input
      checked={checked}
      name="hide_lapsed_before"
      onChange={onChange}
      onClick={onClick}
      type="radio"
      value={value}
    />
    {label}
  </label>
)

const QualificationStatusFilter = ({ search_model, translations }) => {
  const [statuses, setStatuses] = useState(search_model.statuses)
  const [lapsedPeriod, setLapsedPeriod] = useState(
    search_model.hide_lapsed_before
  )

  const statusOptions = Object.entries(translations.statuses).map(toOption)
  const timeOptions = Object.entries(translations.time_periods).map(toOption)

  const handleStatusChange = event => setStatuses(event.target.value)

  const handleLapsedPeriodChange = ({ target: { value } }) =>
    setLapsedPeriod(value)

  const handleLapsedPeriodClick = ({ target: { value } }) => {
    if (value === lapsedPeriod) {
      setLapsedPeriod(null)
    }
  }

  return (
    <div className="qualification-status-filter">
      <div className="status-select">
        <DynamicSelect
          clearable={false}
          currentValue={statuses}
          values={statusOptions}
          placeholderText={translations.placeholder}
          noResultsText=""
          multi={true}
          onSelect={handleStatusChange}
        />
        {statuses.map(status => (
          <input key={status} name="statuses[]" type="hidden" value={status} />
        ))}
      </div>

      {statuses.includes('lapsed') ? (
        <div className="radio-group">
          <div>{translations.hide_lapsed_before}:</div>

          <div className="radio-options">
            {timeOptions.map(({ label, value }) => (
              <RadioOption
                checked={value === lapsedPeriod}
                key={value}
                label={label}
                onChange={handleLapsedPeriodChange}
                onClick={handleLapsedPeriodClick}
                value={value}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

QualificationStatusFilter.propTypes = {
  search_model: PropTypes.shape({
    statuses: PropTypes.arrayOf(PropTypes.string),
    hide_lapsed_before: PropTypes.string,
  }).isRequired,
  translations: PropTypes.shape({
    statuses: PropTypes.object,
    time_periods: PropTypes.object,
    hide_lapsed_before: PropTypes.string,
    placeholder: PropTypes.string,
    no_results: PropTypes.string,
  }).isRequired,
}

export default QualificationStatusFilter
