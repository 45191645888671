// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup } from '@material-ui/core'

import {
  setBottomButtons,
  bottomButtonClicked,
  setProgress,
} from '../../redux/actions'
import { loadSessionsForOffline, offlineSessionsSynced } from '../../redux/app'
import { getBottomButtonsStore } from '../../redux/selectors'
import { get_request } from '../../src/lib/requestToServer'
import { syncOfflineSessions, syncEntities } from 'src/init/syncDataServer'
import Table from '../common/Table'
import Modal from '../Modal'
import NewSessionModal from './NewSessionModal'
import NotificationModal from '../common/NotificationModal'
import moment from 'moment'
import Tag from '../common/Tag'
import { personDisplay } from '../../src/lib/peopleHelper'
import Calendar from '../common/Calendar'
import { orderBy } from 'lodash'
import DeleteSessionButton from './DeleteSessionButton'
import {
  clearGroupOptions,
  clearSessionsDisplay,
  clearTemplateSamples,
  getCurrentPerson,
  getGroupOptions,
  getPeople,
  getSessions,
  getSessionsDisplay,
  getTemplateOptions,
  getTemplateSample,
  getTemplateSamples,
  setGroupOptions,
  setPeople,
  setPeopleInfo,
  setSession,
  setSessionsDisplay,
  setTemplateOptions,
  setTemplateSample,
} from '../../src/lib/idb/common'

import { getNewOfflineSessionId } from '../../src/lib/idb/sessions'
import { v4 as uuidv4 } from 'uuid'

class Index extends Component {
  static propTypes = {
    loadSessionsForOffline: PropTypes.func.isRequired,
    offlineSessionsSynced: PropTypes.func.isRequired,
    onSelectSession: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    bottomButtonClicked: PropTypes.func,
    setBottomButtons: PropTypes.func,
    online: PropTypes.bool,
    setProgress: PropTypes.func,
    showNewSession: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      sessionsDisplayInfo: [],
      creatingSession: false,
      totalSessions: 0,
      totalTemplates: 0,
      totalElementsLoaded: 0,
      offlineReady: false,
    }

    this.controllerUrl = '/unsubmitted_sessions'
  }

  UNSAFE_componentWillReceiveProps = async newProps => {
    const { bottomButtons } = newProps
    const { translations } = this.props

    if (bottomButtons && bottomButtons.clicked) {
      this.props.bottomButtonClicked(null)
      if (bottomButtons.clicked == translations.ubf.new_model.session) {
        let requiredInfoPresent = false
        while (!requiredInfoPresent) {
          const people = await getPeople()
          const groups = await getGroupOptions()
          const templates = await getTemplateOptions()
          requiredInfoPresent = people && groups && templates
          if (!requiredInfoPresent) this.setState({ loading: true })
        }
        this.setState({ creatingSession: true, loading: false })
      }
      if (bottomButtons.clicked == translations.ubf.refresh_data) {
        await this.getDisplayInfo()
      }
      if (bottomButtons.clicked == translations.ubf.prepare_for_offline) {
        this.setIndexBottomButtons(false, true)
        await this.getSessionsForOffline(this.updateOfflinePreparationProgress)
        await this.getTemplatesForOffline()
        this.hideProgressBar()
      }
    }
  }

  componentDidMount = async () => {
    this._ismounted = true
    this.synchronizeWithServer()
    this.getDisplayInfo()
    this.sessionsRetrievalInterval = setInterval(
      this.getDisplayInfo,
      parseInt(UBF.unsubmittedSessionsSyncInterval) * 1000 * 60
    )
    this.checkOfflineReadiness()
  }

  componentDidUpdate = async prevProps => {
    const { online } = this.props

    if (online && prevProps.online != online) {
      this.synchronizeWithServer()
    }
  }

  componentWillUnmount = () => {
    this._ismounted = false
  }

  getDisplayInfo = async () => {
    const { online } = this.props

    if (online) {
      this.setState({ loading: true })
      await this.getSessionsDisplayInfo()
      this.setState({ loading: false })
      await this.getTemplatesDisplayInfo()
      await this.getGroups()
      await this.getPeople()
      this.checkOfflineReadiness()
    } else {
      const sessions = await getSessionsDisplay()

      if (sessions) {
        this.setState({
          sessionsDisplayInfo: sessions,
          totalSessions: sessions.length,
        })
      }
    }
  }

  getSessionsDisplayInfo = async () => {
    await clearSessionsDisplay()
    const data = await get_request(this.controllerUrl, { display: true })
    await setSessionsDisplay(data.sessions)
    this.setState({
      sessionsDisplayInfo: data.sessions,
      totalSessions: data.sessions.length,
    })
  }

  getTemplatesDisplayInfo = async () => {
    const data = await get_request(this.controllerUrl, { templates: true })
    await setTemplateOptions(data)
    this.setState({
      totalTemplates: data.templates.length,
    })
  }

  getSessionsForOffline(callback) {
    const { sessionsDisplayInfo } = this.state
    const sessionIds = sessionsDisplayInfo.map(session => session.id)
    return this.props.loadSessionsForOffline(sessionIds, callback)
  }

  getTemplatesForOffline = async () => {
    const data = await getTemplateOptions()
    await clearTemplateSamples()
    for (const template of data.templates) {
      await this.getTemplateForOffline(template.id, true)
    }
  }

  getTemplateForOffline = async (templateId, gettingAll = false) => {
    const data = await get_request(this.controllerUrl, {
      template_id: templateId,
    })
    await setTemplateSample(data)
    if (gettingAll) this.updateOfflinePreparationProgress()
  }

  updateOfflinePreparationProgress = () => {
    const { totalSessions, totalTemplates, totalElementsLoaded } = this.state
    const newTotalLoaded = totalElementsLoaded + 1
    this.setState({ totalElementsLoaded: newTotalLoaded })
    const progress = (newTotalLoaded / (totalSessions + totalTemplates)) * 100

    if (progress === 100) {
      setTimeout(() => {
        // Hide Prepare for offline button
        this.setIndexBottomButtons(true)
        this.hideProgressBar()
        this.setState({ offlineReady: true })
      }, 2000)
    }
    this.props.setProgress(progress)
  }

  hideProgressBar = () => {
    this.props.setProgress(0)
  }

  setIndexBottomButtons = (offlineReady, loadingOffline = false) => {
    const { translations, showNewSession, setBottomButtons } = this.props
    const buttons = []

    if (loadingOffline) {
      buttons.push(translations.ubf.loading + '...')
    } else if (!offlineReady) {
      buttons.push(translations.ubf.prepare_for_offline)
    }
    buttons.push(translations.ubf.refresh_data)
    if (showNewSession) buttons.push(translations.ubf.new_model.session)

    setBottomButtons(buttons)
  }

  checkOfflineReadiness = async () => {
    const sessionsDisplay = await getSessionsDisplay()
    const sessions = await getSessions()
    const templateOptions = await getTemplateOptions()
    const templates = await getTemplateSamples()

    const elementsRequired =
      (sessionsDisplay?.length || 0) + (templateOptions?.templates?.length || 0)

    const offlineReady =
      sessions.length + templates.length === elementsRequired &&
      elementsRequired !== 0

    if (offlineReady) this.hideProgressBar()

    this.setState({ offlineReady })
    if (this._ismounted) this.setIndexBottomButtons(offlineReady)
  }

  getPeople = async () => {
    const peopleInfo = await get_request(this.controllerUrl, {
      people_info: true,
    })
    setPeopleInfo(peopleInfo)
    const people = await get_request(this.controllerUrl, { people: true })
    await setPeople(people)
  }

  getGroups = async () => {
    await clearGroupOptions()
    const data = await get_request(this.controllerUrl, { groups: true })
    await setGroupOptions(data.groupParams)
  }

  synchronizeWithServer = async reloadSessions => {
    const { online } = this.props
    const canSyncEntities = await syncOfflineSessions()
    if (online) this.props.offlineSessionsSynced()
    if (canSyncEntities) {
      const shouldRetrieveSession = await syncEntities()
      if (shouldRetrieveSession || reloadSessions) this.getDisplayInfo()
    } else this.setState({ loading: false })
  }

  columns = () => {
    const { translations } = this.props
    return [
      {
        name: 'Id',
        options: {
          filter: false,
          searchable: false,
          display: 'excluded',
        },
      },
      {
        name: translations.activerecord.attributes.sessions_for_grader.date,
        options: {
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1] && this.state.ageFilterChecked) {
                return [`From: ${v[0]}`, `To: ${v[1]}`]
              } else if (v[0] && v[1] && !this.state.ageFilterChecked) {
                return `From: ${v[0]}, To: ${v[1]}`
              } else if (v[0]) {
                return `Since: ${v[0]}`
              } else if (v[1]) {
                return `Until: ${v[1]}`
              }
              return false
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '')
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1)
              } else if (filterPos === -1) {
                filterList[index] = []
              }

              return filterList
            },
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              var minDate = new Date(filters[0])
              var maxDate = new Date(filters[1])
              date = new Date(date)
              if (filters[0] && filters[1]) {
                return date < minDate || date > maxDate
              } else if (filters[0]) {
                return date < minDate
              } else if (filters[1]) {
                return date > maxDate
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div className="date-filter">
                <label className="my-training__text--normal_gray">
                  {
                    translations.activerecord.attributes.sessions_for_grader
                      .date
                  }
                </label>
                <FormGroup
                  className="date-filter my-training__flex_distribution--center"
                  row
                >
                  <div>
                    <span className="my-training__text--normal_gray my-training__text--margin_text">
                      {
                        translations.activerecord.attributes.sessions_for_grader
                          .from
                      }
                      :{' '}
                    </span>
                  </div>
                  <div>
                    <Calendar
                      name="min-date"
                      label="min-date"
                      className="date-filter normal_gray"
                      onChange={event => {
                        filterList[index][0] = event.target.value
                        onChange(filterList[index], index, column)
                      }}
                      translations={translations}
                      position="auto-left"
                      selected={filterList[index][0] || ''}
                    />
                  </div>
                  <div>
                    <span className="my-training__text--normal_gray my-training__text--margin_text">
                      {
                        translations.activerecord.attributes.sessions_for_grader
                          .to
                      }
                      :{' '}
                    </span>
                  </div>
                  <div>
                    <Calendar
                      name="max-date"
                      label="max-date"
                      className="date-filter normal_gray"
                      onChange={event => {
                        filterList[index][1] = event.target.value
                        onChange(filterList[index], index, column)
                      }}
                      translations={translations}
                      position="auto-left"
                      selected={filterList[index][1] || ''}
                    />
                  </div>
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: translations.activerecord.attributes.sessions_for_grader.session,
        options: {
          filter: true,
        },
      },
      {
        name: translations.activerecord.attributes.sessions_for_grader.people,
        options: {
          filter: false,
        },
      },
      {
        name: translations.activerecord.attributes.sessions_for_grader.group,
        options: {
          filter: UBF.personGroupsMandatory,
          searchable: UBF.personGroupsMandatory,
          display: UBF.personGroupsMandatory ? 'true' : 'excluded',
        },
      },
      {
        name: '',
        options: {
          display: this.props.online ? true : 'excluded',
          filter: false,
          searchable: false,
          sort: false,
          viewColumns: false,
          customBodyRender: value => {
            return (
              <DeleteSessionButton
                sessionId={value}
                reloadSessions={this.getDisplayInfo}
                confirmMessage={translations.ubf.confirm_delete}
              />
            )
          },
        },
      },
    ]
  }

  formatToTableData = sessions => {
    return sessions
      .filter(s => !s.submitted_at)
      .map(session => {
        var row = []
        row.push(session.id)
        row.push(moment(session.gradedDate).format(UBF.globalDateFormat))
        row.push(session.gradingSessionTemplate.name)

        let people = orderBy(session.people, ['firstNames', 'lastNames']).map(
          person => {
            return person ? (
              <Tag
                key={person.identifier}
                text={personDisplay(person)}
                identifier={person.identifier}
                removable={false}
              />
            ) : null
          }
        )
        row.push(people)
        row.push(session.group?.name)
        row.push(session.id)
        return row
      })
  }

  onCellClick = (colData, cellMeta, rowData) => {
    // Avoid following session's link when clicking on delete button
    if (cellMeta.colIndex === 5) return

    const sessionId = rowData && rowData[0]

    if (!sessionId) return

    this.props.onSelectSession(sessionId)
  }

  closeNewSessionModal = () => {
    this.setState({ creatingSession: false, newSession: {} })
  }

  saveNewSession = () => {
    const { newSession } = this.state

    let newSessionErrors = {}
    if (!newSession?.template) newSessionErrors.template = true
    if (!newSession?.date) newSessionErrors.date = true
    if (
      !newSession ||
      ((!newSession.person_ids || newSession.person_ids.length == 0) &&
        (!newSession.newPeople || newSession.newPeople.length == 0))
    )
      newSessionErrors.people = true

    if (Object.keys(newSessionErrors).length == 0) {
      this.createNewSession(newSession)
      this.setState({
        creatingSession: false,
        newSessionErrors: {},
        newSession: {},
      })
    } else {
      this.setState({ newSessionErrors: newSessionErrors })
    }
  }

  createNewSession = async newSession => {
    const { online } = this.props

    this.setState({ loading: true })
    const grader = await getCurrentPerson()
    const groupParams = await getGroupOptions()
    const people = await getPeople()
    const sampleSessionId = parseInt(newSession.template) * -1
    let session = await getTemplateSample(sampleSessionId)

    if (!session) {
      if (!online) {
        this.setState({ loading: false, onlineRequired: true })
        return
      }
      await this.getTemplateForOffline(newSession.template)
      session = await getTemplateSample(sampleSessionId)
    }

    session.id = await getNewOfflineSessionId()
    session.uuid = uuidv4()
    session.local = true
    session.gradedDate = newSession.date
    session.grader = grader

    session.group_id = newSession.group_id
    if (session.group_id)
      session.group = {
        id: session.group_id,
        name: groupParams?.values.find(
          g => g.value == session.group_id.toString()
        )?.label,
      }
    session.people = []
    if (newSession.person_ids)
      session.people = people.filter(p =>
        newSession.person_ids.includes(p.id.toString())
      )
    if (newSession.newPeople) {
      newSession.newPeople.map(person => {
        session.people.push({
          id: person.identifier,
          name: person.firstName + ' ' + person.lastName,
          firstNames: person.firstName,
          lastNames: person.lastName,
          identifier: person.identifier,
          groupIds: person.group_ids,
          local: true,
        })
      })
    }
    let sampleResult = session.gradingSessionResults[0]
    session.gradingSessionResults = []
    session.people.map(person => {
      session.gradingSessionResults.push(
        this.getSessionResult(session.id, sampleResult, person)
      )
    })

    let sessionsDisplay = await getSessionsDisplay()
    sessionsDisplay.push(session)
    await setSessionsDisplay(sessionsDisplay)
    this.getDisplayInfo()
    await setSession(session)
    await this.synchronizeWithServer(true)
  }

  getSessionResult = (sessionId, sampleResult, person) => {
    let result = { ...sampleResult }
    result.gradingSessionId = sessionId
    result.id = sessionId + '_' + person.id
    result.person = person
    result.personId = person.id
    result.gradings = result.gradings.map(grading => {
      return {
        ...grading,
        id:
          result.id + '_' + grading.activityId + '_' + grading.qualificationId,
        gradingSessionResultId: result.id,
      }
    })
    return result
  }

  getActivityGroup = (resultId, activityGroup) => {
    let group = [...activityGroup]
    return group.map(g => {
      return this.getGradingsFromGroup(resultId, g)
    })
  }

  getGradingsFromGroup = (resultId, group) => {
    if (typeof group == 'string') return group

    let gradings = [...group]
    return gradings.map(grading => {
      let newGrading = { ...grading.grading }
      newGrading.id =
        resultId +
        '_' +
        grading.grading.activityId +
        '_' +
        grading.grading.qualificationId
      newGrading.gradingSessionResultId = resultId
      return { ...grading, grading: newGrading }
    })
  }

  updateNewSession = session => {
    this.setState({ newSession: session })
  }

  closeOnlineRequiredModal = () => {
    this.setState({ onlineRequired: false })
  }

  render() {
    const { translations } = this.props
    const {
      creatingSession,
      loading,
      newSessionErrors,
      sessionsDisplayInfo,
      onlineRequired,
      offlineReady,
    } = this.state

    return (
      <div className="sessions__index">
        <NotificationModal
          notificationType="synchronising"
          translations={translations}
          visible={loading}
        />

        <Modal
          title={translations.activerecord.errors.messages.offline_is_not_ready}
          visible={onlineRequired}
          buttonSave={false}
          hideButtonCancel={true}
          onCloseModal={() => this.closeOnlineRequiredModal()}
          translations={translations}
          zIndex={400}
          center={true}
          extraClasses={'sessions__index--online-required'}
        />

        {creatingSession ? (
          <Modal
            buttonSave={true}
            extraClasses="sessions__index--new-session"
            onCloseModal={this.closeNewSessionModal}
            onSaveModal={this.saveNewSession}
            title={translations.ubf.new_model.session}
            translations={translations}
            visible={true}
            zIndex={200}
          >
            <NewSessionModal
              translations={translations}
              onUpdate={this.updateNewSession}
              errors={newSessionErrors}
            />
          </Modal>
        ) : null}

        <Table
          data={this.formatToTableData(sessionsDisplayInfo)}
          columns={this.columns()}
          actionOnCellClick={this.onCellClick}
          translations={translations}
        />

        {offlineReady ? (
          <div className="sessions__index--bottom-message">
            {translations.ubf.offline_ready}
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const bottomButtons = getBottomButtonsStore(state)
  return { bottomButtons }
}

export default connect(mapStateToProps, {
  loadSessionsForOffline,
  setBottomButtons,
  bottomButtonClicked,
  setProgress,
  offlineSessionsSynced,
})(Index)
