// Copyright Northcote Technology Ltd
import {
  BUCKET_SESSION_CHANGES,
  deleteIdb,
  getAll,
  getDb,
  getSessions,
} from '../../src/lib/idb/common'
import {
  removeOfflineSession,
  setOfflineSessions,
  clearSessionRecordings,
} from '../../src/lib/idb/sessions'
import {
  save_request,
  update_request,
  delete_request,
} from 'src/lib/requestToServer'
import { snakeKeys } from 'js-convert-case'

export async function syncEntities() {
  var tablesToUpdate = [
    BUCKET_SESSION_CHANGES.table,
    '/admin/gradings/',
    '/admin/recordings/',
    '/admin/results/',
  ]

  await getDb()
  let result = false
  await Promise.all(
    tablesToUpdate.map(async store => {
      result = (await updateTable(store)) || result
    })
  )

  return new Promise(resolve => {
    resolve(result)
  })
}

export async function updateTable(store) {
  let result = false
  if (!UBF.online)
    return new Promise(resolve => {
      resolve(result)
    })

  const records = await getAll(store)
  await Promise.all(
    records.map(async record => {
      try {
        result = (await updateRecord(store, record)) || result
      } catch (ex) {
        console.error(ex)
      }
    })
  )

  return new Promise(resolve => {
    resolve(result)
  })
}

export async function updateRecord(store, record) {
  const isRecording = store == '/admin/recordings/'
  const isGrading = store == '/admin/gradings/'
  const isSessionChange = store === BUCKET_SESSION_CHANGES.table
  const action = record.action_server

  if (record.saved_on_server || action == '') return false

  let response = {}
  let tries = 0
  let maxTries = 3
  let ok = false

  while (!ok && tries < maxTries) {
    tries += 1
    if (action == 'delete') {
      response = await delete_request(store, record.id)
      ok = response.ok || response.status == 404
    } else if (action == 'save') {
      if (!isRecording || (isRecording && record.grading_session_id > 0)) {
        // avoid saving records of non saved session
        response = await save_request(store, record)
        ok = response.id || response.status == 404
      }
    } else if (action == 'update') {
      if (isSessionChange) {
        const { id, ...params } = record
        delete params.action_server
        delete params.saved_on_server

        response = await update_request(
          '/quantum/unsubmitted_sessions/',
          { grading_session: params },
          id
        )
      } else if (!isGrading || (isGrading && typeof record.id != 'string')) {
        // avoid saving records of non saved session
        response = await update_request(store, record, record.id)
      }
      ok = response.id || response.status == 404
    }
  }
  if (ok) await deleteIdb(store, record.id, false)

  return true
}

export async function syncOfflineSessions() {
  if (!UBF.online) {
    return new Promise(resolve => {
      resolve(false)
    })
  }

  let result = false
  let response = false

  let sessions = await getSessions()
  let syncSessions = false

  await Promise.all(
    sessions.map(async session => {
      if (session.local) {
        syncSessions = true
        response = await save_request('/unsubmitted_sessions', {
          sync: true,
          session: snakeKeys(session, {
            recursive: true,
            recursiveInArray: true,
          }),
        })
        //Update sessions list with the data from the server
        session.id = 'replace'
        let newSession = response
        let newSessions = sessions.map(
          session => (session.id == 'replace' ? newSession : false) || session
        )

        await setOfflineSessions(newSessions)
        const gradingsDB = await getAll('/admin/gradings/')
        if (gradingsDB) {
          await Promise.all(
            gradingsDB.map(async grading => {
              if (typeof grading.id == 'string') {
                await deleteIdb('/admin/gradings/', grading.id)
              }
            })
          )
        }
        await clearSessionRecordings(newSession.id)
        result = true
      } else if (session.submitted_at) {
        try {
          await syncEntities()
          await update_request('/unsubmitted_sessions/', session, session.id)
          result = true
        } catch (e) {
          console.error(e)
        }
        await removeOfflineSession(session.id)
      }
    })
  )
  if (!syncSessions) {
    result = true
  }

  return new Promise(resolve => {
    resolve(result)
  })
}

export async function syncOfflineSession(session) {
  const params = snakeKeys(session, {
    recursive: true,
    recursiveInArray: true,
  })

  params.recordings.forEach(recording => {
    recording.recording_person_behaviours_attributes =
      recording.recording_person_behaviours.map(rpb => {
        rpb.behaviours_id = rpb.observations.map(
          ({ behaviour_id }) => behaviour_id
        )
        rpb.moods = rpb.observations.map(({ mood }) => mood)
        delete rpb.observations
        return rpb
      })

    delete recording.recording_person_behaviours
  })

  const response = await save_request('/unsubmitted_sessions', {
    sync: true,
    session: params,
  })

  return response
}
