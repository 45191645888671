// Copyright Northcote Technology Ltd
import { combineReducers } from 'redux'

import compliance from './compliance'
import flexlayout from './flexlayout'
import bottomButtons from './bottomButtons'
import edition from './edition'
import creation from './creation'
import loading from './loading'
import sessions from './sessions'
import mySetting from './mySetting'
import heatmapReport from './heatmapReport'
import online from './online'
import resultsErrors from './resultsErrors'

export default combineReducers({
  bottomButtons,
  compliance,
  creation,
  edition,
  flexlayout,
  heatmapReport,
  loading,
  mySetting,
  online,
  resultsErrors,
  sessions,
})
