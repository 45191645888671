// Copyright Northcote Technology Ltd
export const COMPLIANCE_ITEM_SELECTED = 'COMPLIANCE_ITEM_SELECTED'
export const UPDATE_COMPLIANCE_ITEM = 'UPDATE_COMPLIANCE_ITEM'
export const FLEXLAYOUT_TAB_SELECTED = 'FLEXLAYOUT_TAB_SELECTED'
export const RESOLVED_GET_COMPLIANCE_ITEM = 'RESOLVED_GET_COMPLIANCE_ITEM'
export const BOTTOM_BUTTON_CLICKED = 'BOTTOM_BUTTON_CLICKED'
export const SET_BOTTOM_BUTTONS = 'SET_BOTTOM_BUTTONS'
export const GET_CREATE_STATE = 'GET_CREATE_STATE'
export const GET_EDIT_STATE = 'GET_EDIT_STATE'
export const IS_MAXIMIZED = 'IS_MAXIMIZED'
export const SET_PROGRESS = 'SET_PROGRESS'
export const UPDATE_GRADING_SESSION_ITEM = 'UPDATE_GRADING_SESSION_ITEM'
export const UPDATE_GRADING_SESSION_TABLE = 'UPDATE_GRADING_SESSION_TABLE'
export const RESOLVED_GET_GRADING_SESSION_ITEM = 'RESOLVED_GET_COMPLIANCE_ITEM'
export const UPDATE_MY_SETTINGS_ITEM = 'UPDATE_MY_SETTINGS_ITEM'
export const UPDATE_MY_SETTINGS_TABLE = 'UPDATE_MY_SETTINGS_TABLE'
export const RESOLVED_GET_MY_SETTINGS_ITEM = 'RESOLVED_GET_MY_SETTINGS_ITEM'
export const UPDATE_HEATMAP_REPORTS_ITEM = 'UPDATE_HEATMAP_REPORTS_ITEM'
export const UPDATE_HEATMAP_REPORTS_TABLE = 'UPDATE_HEATMAP_REPORTS_TABLE'
export const RESOLVED_GET_HEATMAP_REPORTS_ITEM =
  'RESOLVED_GET_HEATMAP_REPORTS_ITEM'
export const SET_ONLINE = 'SET_ONLINE'
export const SET_OVERALL_GRADING_OPTION = 'SET_OVERALL_GRADING_OPTION'
export const CREATE_RECORDING = 'CREATE_RECORDING'
export const DELETE_RECORDING = 'DELETE_RECORDING'
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS'
export const FETCH_SESSION = 'FETCH_SESSION'
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS'
export const SAVE_SESSION = 'SAVE_SESSION'
export const SAVE_SESSION_SUCCESS = 'SAVE_SESSION_SUCCESS'
export const SET_RESULTS_ERRORS = 'SET_RESULTS_ERRORS'
export const SET_SESSION_ERRORS = 'SET_SESSION_ERRORS'
export const UPDATE_GRADINGS = 'UPDATE_GRADINGS'
export const UPDATE_RECORDING = 'UPDATE_RECORDING'
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_SESSION_CUSTOM_FIELD = 'UPDATE_SESSION_CUSTOM_FIELD'
export const UPDATE_SESSION_DEVICE_TYPE = 'UPDATE_SESSION_DEVICE_TYPE'
export const UPDATE_SESSION_RESULT_FLEET_IDS = 'UPDATE_SESSION_RESULT_FLEET_IDS'
export const UPDATE_SESSION_RESULT_POSITION = 'UPDATE_SESSION_RESULT_POSITION'
export const UPDATE_SESSION_RESULT_SIGNATURE_DATA =
  'UPDATE_SESSION_RESULT_SIGNATURE_DATA'
export const UPDATE_SESSION_RESULT_ABSTRACT = 'UPDATE_SESSION_RESULT_ABSTRACT'
export const OFFLINE_SESSIONS_SYNCED = 'OFFLINE_SESSIONS_SYNCED'
